<template>
  <div class="edit-connection">
    <CForm
      ref="editConnection"
      v-on:submit.prevent="editConnection"
      novalidate
      class="edit-connection-form"
    >
      <!--   HEADER   -->
      <CreateHeader
        :loading="loading"
        :descriptions="descriptions"
        :title="texts.editRabbitMQ.title"
        submit-title="update"
      />
      <!--  CONTENT -->
      <div class="form-content content">
        <!--   Connection Type     -->
        <ClFormRow
          :label="texts.editRabbitMQ.connectionTypeLabel"
          :toolTipText="descriptions.connectionType"
        >
          <template #content>
            <CInput
              :placeholder="texts.editRabbitMQ.connectionTypePlaceholder"
              add-input-classes="col-sm-12"
              disabled="true"
              :value="'RabbitMQ'"
            />
          </template>
        </ClFormRow>
        <!--  ID  -->
        <ClFormRow
          :label="texts.editRabbitMQ.idLabel"
          :toolTipText="descriptions.id"
        >
          <template #content>
            <CInput
              placeholder
              v-model="formData.id"
              add-input-classes="col-sm-12"
              disabled="true"
            />
          </template>
        </ClFormRow>
        <!--   Created   -->
        <ClFormRow
          :label="texts.editRabbitMQ.createdLabel"
          :toolTipText="descriptions.created"
        >
          <template #content>
            <CInput
              :value="created"
              add-input-classes="col-sm-12"
              required
              disabled="true"
            />
          </template>
        </ClFormRow>
        <!-- Space Name   -->
        <ClFormRow
          :label="texts.editRabbitMQ.spaceNameLabel"
          :toolTipText="descriptions.spaceName"
        >
          <template #content>
            <CInput
              :value="formData.spaceName"
              add-input-classes="col-sm-12"
              disabled="true"
            />
          </template>
        </ClFormRow>
        <!--   NAME     -->
        <ClFormRow
          :label="texts.editRabbitMQ.nameLabel"
          :toolTipText="descriptions.name"
        >
          <template #content>
            <CInput
              :placeholder="texts.editRabbitMQ.namePlaceholder"
              v-model="formData.name"
              add-input-classes="col-sm-12"
              required
              invalidFeedback="Required"
              :is-valid="nameValidate"
            />
          </template>
        </ClFormRow>
        <!--  Description  -->
        <ClFormRow
          :label="texts.editRabbitMQ.descriptionLabel"
          :toolTipText="descriptions.description"
        >
          <template #content>
            <CTextarea
              :placeholder="texts.editRabbitMQ.descriptionPlaceholder"
              v-model="formData.description"
              add-input-classes="col-sm-12"
            />
          </template>
        </ClFormRow>
        <!--   Virtual Host*     -->
        <ClFormRow
          :label="texts.editRabbitMQ.virtualHostLabel"
          :toolTipText="descriptions.virtualHost"
        >
          <template #content>
            <CInput
              :placeholder="texts.editRabbitMQ.virtualHostPlaceholder"
              v-model="formData.virtualHost"
              add-input-classes="col-sm-12"
              required
              invalidFeedback="Required"
              :is-valid="virtualValidate"
            />
          </template>
        </ClFormRow>
        <!--  URI* -->
        <ClFormRow
          :label="texts.editRabbitMQ.urlLabel"
          tool-tip-text="URI"
          :toolTipText="descriptions.URI"
        >
          <template #content>
            <CInput
              :placeholder="texts.editRabbitMQ.urlPlaceholder"
              v-model="formData.uri"
              add-input-classes="col-sm-12"
              required
              invalidFeedback="Required"
              :is-valid="uriValidate"
            />
          </template>
        </ClFormRow>
        <!--   Port*     -->
        <ClFormRow
          :label="texts.editRabbitMQ.portLabel"
          :toolTipText="descriptions.Port"
        >
          <template #content>
            <CInput
              type="number"
              :placeholder="texts.editRabbitMQ.portPlaceholder"
              v-model="formData.port"
              add-input-classes="col-sm-12"
              required
              invalidFeedback="Required"
              :is-valid="portValidate"
            />
          </template>
        </ClFormRow>
        <!--  Use SSL*  -->
        <SwitchRow
          md-label="2"
          lg-label="2"
          sm-label="2"
          label="Use SSL*"
          :toolTipText="descriptions.useSSL"
          :valueProp="formData.useSsl"
          @swipeHandler="swipeSSLHandler"
          @updateHandler="sslUpdate"
        />
        <!--  User Name*   -->
        <ClFormRow
          :label="texts.editRabbitMQ.userNameLabel"
          :toolTipText="descriptions.userName"
        >
          <template #content>
            <CInput
              :placeholder="texts.editRabbitMQ.userNamePlaceholder"
              v-model="formData.username"
              add-input-classes="col-sm-12"
              required
              invalidFeedback="Required"
              :is-valid="userValidate"
            />
          </template>
        </ClFormRow>
        <!--    Password    -->
        <ClFormRow
          :label="texts.editRabbitMQ.passwordLabel"
          :toolTipText="descriptions.password"
        >
          <template #content>
            <CInput
              :placeholder="texts.editRabbitMQ.passwordPlaceholder"
              v-model="formData.password"
              add-input-classes="col-sm-12"
              required
              invalidFeedback="Required"
              :is-valid="passValidate"
            />
          </template>
        </ClFormRow>
        <!--  Queue Name      -->
        <ClFormRow
          :label="texts.editRabbitMQ.queueNameLabel"
          :toolTipText="descriptions.queueName"
        >
          <template #content>
            <CInput
              :placeholder="texts.editRabbitMQ.queueNamePlaceholder"
              v-model="formData.queueName"
              add-input-classes="col-sm-12"
              required
              invalidFeedback="Required"
              :is-valid="queueValidate"
            />
          </template>
        </ClFormRow>
        <!--   Exchange   -->
        <ClFormRow
          :label="texts.editRabbitMQ.exchangeLabel"
          :toolTipText="descriptions.exchange"
        >
          <template #content>
            <CInput
              :placeholder="texts.editRabbitMQ.exchangePlaceholder"
              v-model="formData.exchange"
              add-input-classes="col-sm-12"
            />
          </template>
        </ClFormRow>
        <!--     Routing Key   -->
        <ClFormRow
          :label="texts.editRabbitMQ.routineLabel"
          :toolTipText="descriptions.routingKey"
        >
          <template #content>
            <CInput
              :placeholder="texts.editRabbitMQ.routinePlaceholder"
              v-model="formData.routingKey"
              add-input-classes="col-sm-12"
            />
          </template>
        </ClFormRow>
        <!--    Transformer ID*    -->

        <CISelectTranformer
          :label="texts.editRabbitMQ.transformerLabel"
          tool-tip-text="Transformer ID"
          :toolTipText="descriptions.transformerID"
          v-model="formData.transformerId"
          :value="formData.transformerId"
        />

        <CITagField
          :type="model"
          v-model="formData.tags"
          :label="texts.editRabbitMQ.tagsLabel"
          :tooltip="descriptions.tags"
          :disabled="false"
        />
        <CICustomFields
          :type="model"
          :val="formData.customFields"
          @update="formData.customFields = { ...$event }"
        />

        <CIMetaDataField
          v-model="formData.metadata"
          :label="texts.editRabbitMQ.metadataLabel"
          :tooltip="descriptions.metadata"
          :disabled="false"
        />
      </div>
    </CForm>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { cloneDeep } from 'lodash';
import { dateFormate } from '@/utils/dateFormate';
import { formConfig, pageConfig } from '@/config';
import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';
import CreateHeader from '@/shared/components/CreateHeader';
import ClFormRow from '@/shared/components/formComponents/ClFormRow';
import SwitchRow from '@/shared/components/SwitchRow';
import { connections } from '@/config/descriptions/connections';
import { connectionsTexts } from '@/config/pageTexts/connections.json';

export default {
  name: 'EditConnectionRabbitMQ',
  components: {
    CreateHeader,
    SwitchRow,
    ClFormRow,
  },
  data() {
    return {
      model: 'RabbitMqConnection',
      descriptions: {
        ...connections.edit.rabbitMQ,
      },
      texts: {
        ...connectionsTexts,
      },
      selectLabel: formConfig.selectLabel,
      tagPlaceholder: formConfig.tagPlaceholder,
      removeLabel: formConfig.removeLabel,
      customFields: [],
      formData: {
        id: '',
        created: '',
        objectType: 'RabbitMqConnection',
        name: '',
        uri: '',
        virtualHost: '',
        port: 0,
        username: '',
        password: '',
        queueName: '',
        exchange: '',
        routingKey: '',
        transformerId: '',
        useSsl: null,
        customFields: {},
        metadata: {},
        tags: [],
      },

      query: {},
      sortBy: pageConfig.sortBy,
      page: pageConfig.page,
      columnFilter: false,
      itemsPerPage: pageConfig.itemsPerPage,
      passValidate: null,
      transValidate: null,
      nameValidate: null,
      userValidate: null,
      portValidate: null,
      virtualValidate: null,
      queueValidate: null,
      uriValidate: null,
      transformerOptions: [],
      transId: '',
      options: ['tag1', 'tag2', 'tag3'],
    };
  },
  created() {
    this.initialize();
  },
  computed: {
    ...mapGetters('connections', ['success', 'message', 'loading', 'connections', 'connection']),
    ...mapGetters('theme', ['theme']),
    selectErrorClass() {
      const className =
        this.theme === 'main'
          ? 'error-validation--multiselect'
          : 'error-validation';

      return {
        [className]: this.transValidate === false,
      };
    },
    created() {
      return dateFormate(this.formData.created);
    },
  },
  watch: {
    connection() {
      this.formData = cloneDeep(this.connection);
    },
    formData: {
      deep: true,
      handler: function (val) {
        if (val.transformerId) this.transValidate = true;
        if (val.name) this.nameValidate = true;
        if (val.password) this.passValidate = true;
        if (val.username) this.userValidate = true;
        if (val.uri) this.uriValidate = true;
        if (val.queueName) this.queueValidate = true;
        if (val.port) this.portValidate = true;
        if (val.virtualHost) this.virtualValidate = true;
      },
    },
  },
  methods: {
    ...mapActions('connections', ['handleUpdateRabbitMQConnections', 'handleGetRabbitMQConnectionsById']),
    // USE SSL HANDLERS
    swipeSSLHandler(direction) {
      this.sslUpdate(direction === 'right');
    },
    sslUpdate(val) {
      this.formData.useSsl = val;
    },
    initialize() {
      this.handleGetRabbitMQConnectionsById(this.$route.params.id)
          .then(data => {
            routerBreadcrumbs(this.$router.currentRoute, {
              name: data[0].name,
            });
          });
      },
    dateFormate,
    editConnection() {
      this.nameValidate = !!this.formData.name;
      this.transValidate = !!this.formData.transformerId;
      this.passValidate = !!this.formData.password;
      this.userValidate = !!this.formData.username;
      this.portValidate = !!this.formData.port;
      this.virtualValidate = !!this.formData.virtualHost;
      this.uriValidate = !!this.formData.uri;
      this.queueValidate = !!this.formData.queueName;

      // let customFields = [];
      // if (this.formData.customFields) {
      //   this.formData.customFields.forEach((item) => {
      //     customFields.push(item.id);
      //   });
      // }

      let addConstraints = [];
      if (this.formData.useSsl && !this.connection.constraints.length) {
        addConstraints = ['useSsl'];
      }

      const body = [
        {
          id: this.formData.id,
          objectType: this.formData.objectType,
          name: this.formData.name,
          uri: this.formData.uri,
          virtualHost: this.formData.virtualHost,
          constraints: addConstraints,
          port: parseInt(this.formData.port),
          userName: this.formData.username,
          password: this.formData.password,
          queueName: this.formData.queueName,
          exchange: this.formData.exchange,
          routingKey: this.formData.routingKey,
          transformerId: this.formData.transformerId,
          customFields: this.formData.customFields,
          metadata: this.formData.metadata,
          tags: this.formData.tags,
        },
      ];

      if (
        this.nameValidate &&
        this.userValidate &&
        this.passValidate &&
        this.queueValidate &&
        this.uriValidate &&
        this.portValidate &&
        this.virtualValidate
      ) {
        this.handleUpdateRabbitMQConnections(body);
      }
    },
  },
};
</script>

<style lang="scss">
.edit-connection {
  height: 100%;
  .edit-connection-form {
    height: calc(100% - 50px);
    .form-content {
      background: var(--zq-sub-bg);
      height: 100%;
      border-top: 1px solid var(--zq-content-border-color);
    }
  }
  #published-que {
    top: 6px;
    right: -20px;
  }
}
</style>
